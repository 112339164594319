var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container container_responsive" }, [
      _c("div", { staticClass: "page-wrapper page-wrapper_sm number-list" }, [
        _c("h2", { staticClass: "mb-4" }, [
          _vm._v(
            "\n      Политика в отношении обработки персональных данных\n    "
          )
        ]),
        _c("ol", [
          _c("li", [
            _c("strong", [
              _vm._v("Политика в отношении обработки персональных данных")
            ]),
            _c("ol", [
              _vm._v(
                '\n          Настоящая политика обработки персональных данных составлена в соответствии с\n          требованиями Федерального закона от 27.№152-ФЗ «О персональных данных» и определяет\n          порядок обработки персональных данных и меры по обеспечению безопасности персональных\n          данных ООО "Реалти Клауд" (далее – Оператор).\n          '
              ),
              _c("br"),
              _c("br"),
              _c("li", [
                _vm._v(
                  "\n            Оператор ставит своей важнейшей целью и условием осуществления своей деятельности\n            соблюдение прав и свобод человека и гражданина при обработке его персональных данных,\n            в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.\n          "
                )
              ]),
              _c("li", [
                _vm._v(
                  "\n            Настоящая политика Оператора в отношении обработки персональных данных (далее –\n            Политика) применяется ко всей информации, которую Оператор может получить о\n            посетителях веб-сайта\n            "
                ),
                _c("a", { attrs: { href: "https://egrn.realtycloud.ru" } }, [
                  _vm._v("https://egrn.realtycloud.ru")
                ]),
                _vm._v(".\n          ")
              ])
            ])
          ]),
          _c("li", [
            _c("strong", [_vm._v("Основные понятия, используемые в Политике")]),
            _c("ol", [
              _c("li", [
                _vm._v(
                  "\n            Автоматизированная обработка персональных данных – обработка персональных данных с\n            помощью средств вычислительной техники;\n          "
                )
              ]),
              _c("li", [
                _vm._v(
                  "\n            Блокирование персональных данных – временное прекращение обработки персональных данных\n            (за исключением случаев, если обработка необходима для уточнения персональных данных);\n          "
                )
              ]),
              _c("li", [
                _vm._v(
                  "\n            Веб-сайт – совокупность графических и информационных материалов, а также программ для\n            ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу\n            "
                ),
                _c("a", { attrs: { href: "https://egrn.realtycloud.ru" } }, [
                  _vm._v("https://egrn.realtycloud.ru")
                ]),
                _vm._v(";\n          ")
              ]),
              _c("li", [
                _vm._v(
                  "\n            Информационная система персональных данных — совокупность содержащихся в базах данных\n            персональных данных, и обеспечивающих их обработку информационных технологий и\n            технических средств;\n          "
                )
              ]),
              _c("li", [
                _vm._v(
                  "\n            Обезличивание персональных данных — действия, в результате которых невозможно\n            определить без использования дополнительной информации принадлежность персональных\n            данных конкретному Пользователю или иному субъекту персональных данных;\n          "
                )
              ]),
              _c("li", [
                _vm._v(
                  "\n            Обработка персональных данных – любое действие (операция) или совокупность действий\n            (операций), совершаемых с использованием средств автоматизации или без использования\n            таких средств с персональными данными, включая сбор, запись, систематизацию,\n            накопление, хранение, уточнение (обновление, изменение), извлечение, использование,\n            передачу (распространение, предоставление, доступ), обезличивание, блокирование,\n            удаление, уничтожение персональных данных;\n          "
                )
              ]),
              _c("li", [
                _vm._v(
                  "\n            Оператор – государственный орган, муниципальный орган, юридическое или физическое\n            лицо, самостоятельно или совместно с другими лицами организующие и (или)\n            осуществляющие обработку персональных данных, а также определяющие цели обработки\n            персональных данных, состав персональных данных, подлежащих обработке, действия\n            (операции), совершаемые с персональными данными;\n          "
                )
              ]),
              _c("li", [
                _vm._v(
                  "\n            Персональные данные – любая информация, относящаяся прямо или косвенно к определенному\n            или определяемому Пользователю веб-сайта\n            "
                ),
                _c("a", { attrs: { href: "https://egrn.realtycloud.ru" } }, [
                  _vm._v("https://egrn.realtycloud.ru")
                ]),
                _vm._v(";\n          ")
              ]),
              _c("li", [
                _vm._v(
                  "\n            Пользователь – любой посетитель веб-сайта\n            "
                ),
                _c("a", { attrs: { href: "https://egrn.realtycloud.ru" } }, [
                  _vm._v("https://egrn.realtycloud.ru")
                ]),
                _vm._v(";\n          ")
              ]),
              _c("li", [
                _vm._v(
                  "\n            Предоставление персональных данных – действия, направленные на раскрытие персональных\n            данных определенному лицу или определенному кругу лиц;\n          "
                )
              ]),
              _c("li", [
                _vm._v(
                  "\n            Распространение персональных данных – любые действия, направленные на раскрытие\n            персональных данных неопределенному кругу лиц (передача персональных данных) или на\n            ознакомление с персональными данными неограниченного круга лиц, в том числе\n            обнародование персональных данных в средствах массовой информации, размещение в\n            информационно-телекоммуникационных сетях или предоставление доступа к персональным\n            данным каким-либо иным способом;\n          "
                )
              ]),
              _c("li", [
                _vm._v(
                  "\n            Трансграничная передача персональных данных – передача персональных данных на\n            территорию иностранного государства органу власти иностранного государства,\n            иностранному физическому или иностранному юридическому лицу;\n          "
                )
              ]),
              _c("li", [
                _vm._v(
                  "\n            Уничтожение персональных данных – любые действия, в результате которых персональные\n            данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления\n            содержания персональных данных в информационной системе персональных данных и (или)\n            результате которых уничтожаются материальные носители персональных данных.\n          "
                )
              ])
            ])
          ]),
          _c("li", [
            _c("strong", [
              _vm._v(
                "Оператор может обрабатывать следующие персональные данные Пользователя"
              )
            ]),
            _c("ol", [
              _c("li", [_vm._v("Фамилия, имя, отчество.")]),
              _c("li", [_vm._v("Электронный адрес.")]),
              _c("li", [_vm._v("Номера телефонов.")]),
              _c("li", [_vm._v("Платежные реквизиты")]),
              _c("li", [
                _vm._v(
                  "\n            Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч.\n            файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика и Гугл\n            Аналитика и других).\n          "
                )
              ]),
              _c("li", [
                _vm._v(
                  "\n            Вышеперечисленные данные далее по тексту Политики объединены общим понятием\n            Персональные данные.\n          "
                )
              ])
            ])
          ]),
          _c("li", [
            _c("strong", [_vm._v("Цели обработки персональных данных")]),
            _c("ol", [
              _c("li", [
                _vm._v(
                  "\n            Цель обработки персональных данных Пользователя — информирование Пользователя\n            посредством отправки электронных писем; заключение, исполнение и прекращение\n            гражданско-правовых договоров; предоставление доступа Пользователю к сервисам,\n            информации и/или материалам, содержащимся на веб-сайте.\n          "
                )
              ]),
              _c("li", [
                _vm._v(
                  "\n            Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и\n            услугах, специальных предложениях и различных событиях. Пользователь всегда может\n            отказаться от получения информационных сообщений, направив Оператору письмо на адрес\n            электронной почты\n            "
                ),
                _c("a", { attrs: { href: "mailto:help@realtycloud.ru" } }, [
                  _vm._v("help@realtycloud.ru")
                ]),
                _vm._v(
                  "\n            с пометкой «Отказ от уведомлениях о новых продуктах и услугах и специальных\n            предложениях».\n          "
                )
              ]),
              _c("li", [
                _vm._v(
                  "\n            Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики,\n            служат для сбора информации о действиях Пользователей на сайте, улучшения качества\n            сайта и его содержания.\n          "
                )
              ])
            ])
          ]),
          _c("li", [
            _c("strong", [
              _vm._v("Правовые основания обработки персональных данных")
            ]),
            _c("ol", [
              _c("li", [
                _vm._v(
                  "\n            Оператор обрабатывает персональные данные Пользователя только в случае их заполнения\n            и/или отправки Пользователем самостоятельно через специальные формы, расположенные на\n            сайте\n            "
                ),
                _c("a", { attrs: { href: "https://egrn.realtycloud.ru" } }, [
                  _vm._v("https://egrn.realtycloud.ru")
                ]),
                _vm._v(
                  ". Заполняя\n            соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь\n            выражает свое согласие с данной Политикой.\n          "
                )
              ]),
              _c("li", [
                _vm._v(
                  "\n            Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено\n            в настройках браузера Пользователя (включено сохранение файлов «cookie» и\n            использование технологии JavaScript).\n          "
                )
              ])
            ])
          ]),
          _c("li", [
            _c("strong", [
              _vm._v(
                "Порядок сбора, хранения, передачи и других видов обработки персональных данных"
              )
            ]),
            _c("ol", [
              _vm._v(
                "\n          Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается\n          путем реализации правовых, организационных и технических мер, необходимых для выполнения\n          в полном объеме требований действующего законодательства в области защиты персональных\n          данных.\n          "
              ),
              _c("br"),
              _c("br"),
              _c("li", [
                _vm._v(
                  "\n            Оператор обеспечивает сохранность персональных данных и принимает все возможные меры,\n            исключающие доступ к персональным данным неуполномоченных лиц.\n          "
                )
              ]),
              _c("li", [
                _vm._v(
                  "\n            Персональные данные Пользователя никогда, ни при каких условиях не будут переданы\n            третьим лицам, за исключением случаев, связанных с исполнением действующего\n            законодательства.\n          "
                )
              ]),
              _c("li", [
                _vm._v(
                  "\n            В случае выявления неточностей в персональных данных, Пользователь может\n            актуализировать их самостоятельно, путем направления Оператору уведомление на адрес\n            электронной почты Оператора\n            "
                ),
                _c("a", { attrs: { href: "mailto:help@realtycloud.ru" } }, [
                  _vm._v("help@realtycloud.ru")
                ]),
                _vm._v(
                  "\n            с пометкой «Актуализация персональных данных».\n          "
                )
              ]),
              _c("li", [
                _vm._v(
                  "\n            Срок обработки персональных данных является неограниченным. Пользователь может в любой\n            момент отозвать свое согласие на обработку персональных данных, направив Оператору\n            уведомление посредством электронной почты на электронный адрес Оператора\n            "
                ),
                _c("a", { attrs: { href: "mailto:help@realtycloud.ru" } }, [
                  _vm._v("help@realtycloud.ru")
                ]),
                _vm._v(
                  "\n            с пометкой «Отзыв согласия на обработку персональных данных».\n          "
                )
              ])
            ])
          ]),
          _c("li", [
            _c("strong", [
              _vm._v("Трансграничная передача персональных данных")
            ]),
            _c("ol", [
              _c("li", [
                _vm._v(
                  "\n            Оператор до начала осуществления трансграничной передачи персональных данных обязан\n            убедиться в том, что иностранным государством, на территорию которого предполагается\n            осуществлять передачу персональных данных, обеспечивается надежная защита прав\n            субъектов персональных данных.\n          "
                )
              ]),
              _c("li", [
                _vm._v(
                  "\n            Трансграничная передача персональных данных на территории иностранных государств, не\n            отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия\n            согласия в письменной форме субъекта персональных данных на− трансграничную передачу\n            его персональных данных и/или исполнения договора, стороной которого является субъект\n            персональных данных.\n          "
                )
              ])
            ])
          ]),
          _c("li", [
            _c("strong", [_vm._v("Заключительные положения")]),
            _c("ol", [
              _c("li", [
                _vm._v(
                  "\n            Пользователь может получить любые разъяснения по интересующим вопросам, касающимся\n            обработки его персональных данных, обратившись к Оператору с помощью электронной почты\n            "
                ),
                _c("a", { attrs: { href: "mailto:help@realtycloud.ru" } }, [
                  _vm._v("help@realtycloud.ru")
                ]),
                _vm._v(".\n          ")
              ]),
              _c("li", [
                _vm._v(
                  "\n            В данном документе будут отражены любые изменения политики обработки персональных\n            данных Оператором. Политика действует бессрочно до замены ее новой версией.\n          "
                )
              ]),
              _c("li", [
                _vm._v(
                  "\n            Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу\n            "
                ),
                _c(
                  "a",
                  { attrs: { href: "https://egrn.realtycloud.ru/privacy" } },
                  [_vm._v("https://egrn.realtycloud.ru/privacy")]
                ),
                _vm._v(".\n          ")
              ])
            ])
          ])
        ]),
        _c("h5", [_vm._v("Реквизиты")]),
        _c("p", { staticClass: "mb-0" }, [
          _vm._v("\n      ООО «Реалти Клауд» "),
          _c("br"),
          _vm._v(
            "\n      ИНН 1650367985 | КПП 165001001 | ОГРН 1181690067932 "
          ),
          _c("br"),
          _vm._v(
            "\n      Адрес: 423800, г.Набережные Челны, ул.Машиностроительная, 91, пом.59 "
          ),
          _c("br"),
          _vm._v("\n      E-mail: "),
          _c("a", { attrs: { href: "mailto:help@realtycloud.ru" } }, [
            _vm._v("help@realtycloud.ru")
          ]),
          _c("br")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }