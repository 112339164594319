var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container container_responsive" }, [
      _c("div", { staticClass: "page-wrapper page-wrapper_sm" }, [
        _c("h2", { staticClass: "text-center mb-5" }, [
          _vm._v("\n      Прайс-лист\n    ")
        ]),
        _c("h4", { staticClass: "mb-3" }, [
          _vm._v("\n      Разовые услуги\n    ")
        ]),
        _c("table", { staticClass: "table table-bordered mb-5" }, [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v("Услуга")]),
              _c("th", [_vm._v("За 1 шт.")])
            ])
          ]),
          _c("tbody", [
            _c("tr", [
              _c("td", [_vm._v("Отчет из ЕГРН об объекте")]),
              _c("td", [_vm._v("249 руб.")])
            ]),
            _c("tr", [
              _c("td", [_vm._v("Отчет из ЕГРН о переходе прав")]),
              _c("td", [_vm._v("299 руб.")])
            ]),
            _c("tr", [
              _c("td", [
                _vm._v("\n            Отчет о рисках "),
                _c("br"),
                _vm._v(
                  "\n            Регионы: Москва, Санкт-Петербург\n          "
                )
              ]),
              _c("td", [_vm._v("2990 руб. / 4990 руб.")])
            ]),
            _c("tr", [
              _c("td", [_vm._v("Контроль сделок")]),
              _c("td", [_vm._v("17 руб")])
            ]),
            _c("tr", [
              _c("td", [_vm._v("Регистрация сделки")]),
              _c("td", [_vm._v("4990 руб.")])
            ])
          ])
        ]),
        _c("h4", { staticClass: "mb-3" }, [
          _vm._v("\n      Пакетные предложения\n    ")
        ]),
        _c("table", { staticClass: "table table-bordered" }, [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v("Услуга")]),
              _c("th", [_vm._v("от 5 шт.")]),
              _c("th", [_vm._v("от 500 шт.")]),
              _c("th", [_vm._v("от 5000 шт.")])
            ])
          ]),
          _c("tbody", [
            _c("tr", [
              _c("td", [_vm._v("Отчет из ЕГРН об объекте (за 1 шт.)")]),
              _c("td", [_vm._v("49 руб.")]),
              _c("td", [_vm._v("17 руб.")]),
              _c("td", [_vm._v("8 руб.")])
            ]),
            _c("tr", [
              _c("td", [_vm._v("Отчет из ЕГРН о переходе прав (за 1 шт.)")]),
              _c("td", [_vm._v("79 руб.")]),
              _c("td", [_vm._v("17 руб.")]),
              _c("td", [_vm._v("8 руб.")])
            ]),
            _c("tr", [
              _c("td", [_vm._v("Контроль сделок (за 1 шт.)")]),
              _c("td", [_vm._v("16 руб.")]),
              _c("td", [_vm._v("10 руб.")]),
              _c("td", [_vm._v("10 руб.")])
            ])
          ])
        ]),
        _c("table", { staticClass: "table table-bordered" }, [
          _c("thead", [
            _c("th", [_vm._v("Услуга")]),
            _c("th", [_vm._v("от 5 шт.")]),
            _c("th", [_vm._v("от 20 шт.")]),
            _c("th", [_vm._v("от 100 шт.")])
          ]),
          _c("tbody", [
            _c("tr", [
              _c("td", [
                _vm._v("\n            Отчет о рисках "),
                _c("br"),
                _vm._v(
                  "\n            Регионы: Москва, Санкт-Петербург (за 1 шт.)\n          "
                )
              ]),
              _c("td", [_vm._v("1990 руб. / 2990 руб.")]),
              _c("td", [_vm._v("990 руб. / 1990 руб.")]),
              _c("td", [_vm._v("490 руб. / 990 руб.")])
            ]),
            _c("tr", [
              _c("td", [_vm._v("Регистрация сделки (за 1 шт.)")]),
              _c("td", [_vm._v("1990 руб.")]),
              _c("td", [_vm._v("990 руб.")]),
              _c("td", [_vm._v("490 руб.")])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }